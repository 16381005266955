import { TermItemDefinition } from '@/services/term'
import { defineStore } from 'pinia'
export const useSearchRebookingPeriodStore = defineStore('searchRebookingPeriodStore', {
  state: () => ({
    animalTypeId: null as number | null,
    validFrom: null as string | null,
    validUntil: null as string | null,
    items: [
      { key: 'animalTypeId', operation: ':', itemType: 'number' },
      { key: 'validFrom', operation: '>=' },
      { key: 'validUntil', operation: '<=' }
    ] as TermItemDefinition[]
  })
})
