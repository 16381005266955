<template>
  <div>
    <h2 v-html="$t('search_rebooking_period_title')"></h2>
    <!-- Periode von -->
    <DbmMonthPicker
      name="SearchRebookingPeriodStore_validFrom"
      id="SearchRebookingPeriodStore_validFrom"
      v-model="searchCardStore.validFrom"
      :dateLabel="$t('search_rebooking_period_from')"
      :rules="[$rules.isCHMonth, $rules.required, sameYearRule]"
    />
    <!-- Periode bis -->
    <DbmMonthPicker
      name="SearchRebookingPeriodStore_validUntil"
      id="SearchRebookingPeriodStore_validUntil"
      v-model="searchCardStore.validUntil"
      :dateLabel="$t('search_rebooking_period_until')"
      :rules="[$rules.isCHMonth, $rules.required, sameYearRule]"
      returnType="lastOfMonth"
    />
    <!-- Tierart -->
    <v-select
      id="search_rebooking_period_animal_type"
      required
      v-model="searchCardStore.animalTypeId"
      :items="animalTypesProduction"
      item-value="id"
      :item-title="this.$getLangKey()"
      :label="$t('search_rebooking_period_animal_type')"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex'
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useSearchRebookingPeriodStore } from '@/store/SearchRebookingPeriodStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchRebookingPeriod',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    animalTypesProduction() {
      return useAnimalTypesStore().AnimalTypesProduction
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker
  },
  methods: {
    sameYearRule(x) {
      if (!this.searchCardStore.validFrom || !this.searchCardStore.validUntil) {
        return true
      }

      const [fromYear, fromMonth, fromDay] = this.searchCardStore.validFrom.split('-')
      const [untilYear, untilMonth, untilDay] = this.searchCardStore.validUntil.split('-')

      if (`${fromYear}${fromMonth}${fromDay}` > `${untilYear}${untilMonth}${untilDay}`) {
        return this.$t('same_year_rule_to_date_bigger')
      }

      const validFromDate = new Date(this.searchCardStore.validFrom)
      const validUntilDate = new Date(this.searchCardStore.validUntil)

      const difference = validUntilDate - validFromDate

      if (difference < 31536000000) {
        return true
      } else {
        return this.$t('same_year_rule_longer_than_a_year')
      }
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchRebookingPeriodStore(), true)
    useAnimalTypesStore().fetchAnimalTypesProduction()
  }
})
</script>
